var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    on: {
      mouseenter: function mouseenter($event) {
        _vm.showTooltip = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.showTooltip = false;
      }
    }
  }, [_vm.content || _vm.$slots.content ? [!_vm.showTooltip ? _c("div", {
    class: {
      txtEli: _vm.lineClamp2,
      "break-all": _vm.lineClamp2,
      "text-eli": !_vm.lineClamp2
    },
    staticStyle: {
      "margin-bottom": "2px",
      height: "100%"
    }
  }, [_vm.$slots.content ? [_vm._t("content")] : _c("span", [_vm._v(_vm._s(_vm.content || "-"))])], 2) : _c("Tooltip", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      content: _vm.content,
      placement: _vm.placement,
      transfer: _vm.transfer,
      maxWidth: _vm.maxWidth,
      theme: _vm.theme,
      delay: _vm.delay
    }
  }, [_vm.$slots.content ? _c("div", {
    staticStyle: {
      "margin-bottom": "3px"
    }
  }, [_vm._t("content")], 2) : _c("div", {
    class: {
      txtEli: _vm.lineClamp2,
      "text-eli": !_vm.lineClamp2,
      "break-all": _vm.lineClamp2
    },
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.copy(_vm.content);
      }
    }
  }, [_vm._v(_vm._s(_vm.content || "-"))])])] : _c("span", [_vm._v("-")])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };