import "core-js/modules/es7.array.includes";
import cookies from "./util.cookies";
import log from "./util.log";
import db from "./util.db";
import Setting from '@/setting';
var util = {
  cookies: cookies,
  log: log,
  db: db
};

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function (_ref) {
  var title = _ref.title,
    count = _ref.count;
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
};
function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }
  setTimeout(task, 16);
}

/**
 * @param str1 {string}
 * @param str2 {string}
 * @returns {boolean}
 */
util.equalIgnoreCase = function (str1, str2) {
  return str1.toUpperCase() === str2.toUpperCase();
};
/**
 * 判断 变量是否存在值 （主要用于 与 ！a 做区别，排除0的情况）
 * @param value
 * @returns {boolean}
 */
util.notExitValue = function (value) {
  var values = [null, undefined];
  return values.includes(value);
};
/**
 * @desc 判断为非数值 （包含数字数值，字符串数字） 可作为数值判空的依据
 * @param {String,Number} value
 */
util.isNotNumberValue = function (value) {
  return !(!isNaN(parseFloat(value)) && isFinite(value));
};
export { requestAnimation };
export default util;