var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger"
  }, [_c("span", [_vm._v("欢迎您~")]), _c("Dropdown", {
    attrs: {
      trigger: "hover",
      transfer: ""
    },
    on: {
      "on-click": _vm.handleClick
    }
  }, [_c("div", {
    staticClass: "dropdown-content cursor-point"
  }, [_c("Avatar", {
    attrs: {
      src: _vm.info.thumbAvatar
    }
  }), _c("span", [_vm._v(_vm._s(_vm.info.wxName))])], 1), _c("DropdownMenu", {
    attrs: {
      slot: "list"
    },
    slot: "list"
  }, [_c("DropdownItem", {
    attrs: {
      name: "password-reset"
    }
  }, [_c("div", {
    staticClass: "dropdown-item-box"
  }, [_c("Icon", {
    attrs: {
      type: "md-refresh icon"
    }
  }), _c("span", [_vm._v("修改密码")])], 1)]), _c("DropdownItem", {
    attrs: {
      divided: "",
      name: "logout"
    }
  }, [_c("div", {
    staticClass: "dropdown-item-box"
  }, [_c("Icon", {
    attrs: {
      type: "md-log-out icon"
    }
  }), _c("span", [_vm._v("退出登录")])], 1)])], 1)], 1), _c("iPasswordReset", {
    model: {
      value: _vm.isShowPasswdModal,
      callback: function callback($$v) {
        _vm.isShowPasswdModal = $$v;
      },
      expression: "isShowPasswdModal"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };