export default {
  path: "/global-statistics",
  title: "全局统计",
  children: [{
    path: "/global-statistics/revenue-analysis",
    title: "收入分析"
  }, {
    path: "/global-statistics/sales-conversion-analysis",
    title: "销售转化分析"
  }, {
    path: "/global-statistics/business-achievement-rate",
    title: "业务统计"
  }, {
    path: "/global-statistics/user-statistics",
    title: "人员统计"
  },
  // {
  //     path: "/global-statistics/month-writeoffRate",
  //     title: "月销账率",
  // },
  // {
  //     path: "/global-statistics/order-statistics",
  //     title: "订单统计",
  // },
  {
    path: "/global-statistics/Pipeline",
    title: "Pipeline"
  }, {
    path: "/global-statistics/RFM",
    title: "RFM分析"
  }
  // {
  //     path: "/global-statistics/expense-statistics",
  //     title: "费用统计",
  // },
  ]
};