export default {
  name: "fk-drawer-right",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    ok: function ok() {
      // 确认按钮 关闭drawer
      this.$emit('input', false);
      this.$emit('on-ok');
    },
    reset: function reset() {
      this.$emit('on-reset'); // 重置不关闭
    },
    visibleChange: function visibleChange(value) {
      this.$emit('input', value);
      if (!value) this.$emit("on-close"); // 关闭事件 不刷新数据
      else this.$emit("on-open");
    }
  }
};