var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Layout", {
    staticClass: "i-layout"
  }, [_c("Sider", {
    staticClass: "i-layout-sider",
    class: _vm.siderClasses,
    attrs: {
      width: _vm.menuSideWidth
    }
  }, [_c("i-menu-side")], 1), _c("Layout", {
    staticClass: "i-layout-inside",
    class: _vm.insideClasses
  }, [_c("Header", {
    staticClass: "i-layout-header flex-align-center flex-pack-justify",
    class: _vm.headerClasses
  }, [_c("system-notice", {
    model: {
      value: _vm.systemNotificationNotReadCount,
      callback: function callback($$v) {
        _vm.systemNotificationNotReadCount = $$v;
      },
      expression: "systemNotificationNotReadCount"
    }
  }), _c("i-header-user")], 1), _c("Content", {
    staticClass: "i-layout-content fk-common-full-screen",
    class: _vm.contentClasses
  }, [_c("transition", {
    attrs: {
      name: "fade-quick"
    }
  }, [_vm.tabs ? _c("i-tabs") : _vm._e()], 1), _c("div", {
    staticClass: "i-layout-content-main fk-common-full-screen full-screen-scroll"
  }, [_c("transition", {
    on: {
      "after-enter": _vm.afterRouterChange
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.keepAlive
    }
  }, [_vm.loadRouter ? _c("router-view", {
    ref: "child"
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };